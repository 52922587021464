import { debounce } from 'lodash';

const debounceTime = 800;

var ProductFilter = {
    resetButton: $('.js-reset-filter'),
    init: function() {

        /**
         * On Megamenu Item click
         * Updated filter based on the clicked menu item
         */
        $(document).on(
            'click',
            '.filter-list a, .dropdown-homepage, .js-filter-tree-anchor',
            (e) => {
                let hashes = ProductFilter.getHashFromString($(e.currentTarget).attr('href'));
                $(".select2[name=level_1_id]").val(hashes.obj.level_1_id).trigger('change');
                $(".select2[name=level_2_id]").val(hashes.obj.level_2_id).trigger('change');
            });

        $(document).on(
            'change',
            '.filterbar .level_1, .filterbar .level_2',
            _.debounce(() => ProductFilter.reloadProductsFilter(), debounceTime));

        $(document).on(
            'change',
            '.filterbar .filterbar__group.ajax_filter',
            _.debounce(() => ProductFilter.reloadResults(false), debounceTime));

        $(document).on('click', 
            '.filterbar2 .js-reset-filter', 
            ProductFilter.reset);
    },

    /**
     * Get Hash from String
     * return object of string an hashes as object
     * @param {*} data
     * @returns
     */
    getHashFromString: function(data) {
        if(!data) return;
        let hashString = data.split('#')[1];
        let hashObject = {};

        hashString.split("&").forEach((e) => {
            let tmp = e.split('=');
            hashObject[tmp[0]] = tmp[1];
        });

        return {
            string: hashString,
            obj: hashObject
        };
    },

    reset: function() {
        ProductFilter.resetButton.addClass('disabled');
        window.location = $('#products_list').val() + '#level_1_id=' + $('select[name=level_1_id]').val();

        window.location.reload();
    },

    /**
     * Get Hash from URL
     * @param {*} key
     * @returns Object
     */
    getHash: function(key) {
        var params_tmp = location.hash.substr(1).split('&'),
            params = {};

        params_tmp.forEach(function(val) {
            var splitter = val.split('=');
            params[splitter[0]] = splitter[1];
        });

        if (key) {
            return params[key];
        }

        return params;
    },

    addLoadingOverlay: function(e) {
        $('.resultlist').html(`
            <div class="fallback fallback--headline"></div>
            <div class="fallback fallback--span"></div>
            <div class="fallback fallback--result"></div>
            <div class="fallback fallback--result"></div>
            <div class="fallback fallback--result"></div>
        `);
    },

    removeLoadingOverlay: function(data) {
        $('.resultlist').html(data);
    },

    reloadProductsFilter: function(e, initial) {
        console.log('== reloadProductsFilter == ');

        var hashes = ProductFilter.getHash();

        /** CATEGORY LEVEL MANAGEMENT */
        var level1Elem = $('.filterbar .level_1');

        if (initial) {
            if (hashes && hashes.level_1_id) {
                level1Elem.val(hashes.level_1_id);
            }
        }

        var level1Id = level1Elem.val();

        $('.filterbar .level_2')
            .parent()
            .removeClass('d-block');

        $('.filterbar .level_2')
            .parent()
            .addClass('d-none');

        var currentLevel1Elem = $('.filterbar .category_level_' + level1Id);

        if (currentLevel1Elem.length) {
            currentLevel1Elem.parent().removeClass('d-none');
            currentLevel1Elem.parent().addClass('d-block');

            if (initial) {
                if (hashes && hashes.level_2_id) {
                    currentLevel1Elem.val(hashes.level_2_id);
                }
            }
        }

        ProductFilter.reloadFilter(initial);
        ProductFilter.reloadResults(initial);

        console.log('== /reloadProductsFilter == ');
    },

    /**
     * Load Select2 only if on the Products page. 
     * Otherwise other Select2 instances will not work
     */
    reloadSelect2: function() {
        if ($('body').hasClass('page-products')) {
            $('.select2').select2({
                closeOnSelect: false,
            });
        }
    },

    reloadUI: function() {
        console.log('Reload UI');

        $('.js-shop-products').on('click', function(e) {
            e.preventDefault();

            let element = $(this);
            let table = element.closest('.result-item').find('.result-item__products');

            table.toggleClass('show');
        });

        window._reloadInventoryState();
        ProductFilter.resetButton.removeClass('disabled');
    },

    reloadFilter: function(initial) {
        var ajaxUrl = $('#products_ajax_get_filter').val();

        if (!initial) {
            var hashes = ProductFilter.getCurrentHashState();
        } else {
            var hashes = location.hash.substr(1);
        }

        $.ajax(ajaxUrl, {
            method: 'POST',
            data: {
                _token: $('#_token').val(),
                params: hashes,
            },
            success: function(resp) {
                $('.ajax_filter').html(resp);
                ProductFilter.reloadSelect2();
            },
            error: function(resp) {
                console.log('reloadFilter: Could not load Filter!');
                console.log(resp);
                alert($('#i18n_generic_ajax_error').val());
            },
        });
    },

    reloadResults: function(initial) {
        var ajaxUrl = $('#products_ajax_get_results').val();

        if (!initial) {
            var hashes = ProductFilter.getCurrentHashState();
            ProductFilter.addLoadingOverlay();
        } else {
            var hashes = location.hash.substr(1);
        }

        ProductFilter.addLoadingOverlay();

        $.ajax(ajaxUrl, {
            method: 'POST',
            data: {
                _token: $('#_token').val(),
                params: hashes,
            },
            success: function(resp) {
                ProductFilter.removeLoadingOverlay();
                $('.resultlist').html(resp);

                ProductFilter.reloadUI();
            },
            error: function(resp) {
                console.log('reloadResults: Could not load Filter!');
                console.log(resp);

                alert($('#i18n_generic_ajax_error').val());
                ProductFilter.resetButton.removeClass('disabled');
            }
        });
    },

    getCurrentHashState: function() {
        let url = '';

        /**
         * Normale Selects
         */
        $('.filterbar .filter-item select').each(function() {
            if ($(this).parent().hasClass('d-none')) return;

            let name = $(this).attr('name');
            let search = '';
            let selected = $(this).find(':selected');

            if (!selected.length) return (url += name + '=' + search + '&');

            selected.each(function() {
                search += `${$(this).val()}|`;
            });

            url += name + '=' + search.replace(/\|$/, '') + '&';
        });

        window.location.hash = url.trimEnd('&');
        return url.trimEnd('&');
    },
};

/**
 * Collapse Filter-Tree-Items and
 * select active Collapse on the basis of url hash
 */
const ProductFilterCollapse = {
    items: $('.filter-nav'),
    multiple: false,
    init: () => {
        if(!ProductFilterCollapse.items || !ProductFilterCollapse.items.length) return;

        ProductFilterCollapse.items.on('click', '.filter-nav__toggle', (e) => ProductFilterCollapse.toggle($(e.delegateTarget)) );
        ProductFilterCollapse.items.on('click', '.filter-nav__header', (e) => ProductFilterCollapse.toggle($(e.delegateTarget)) );
        ProductFilterCollapse.toggleHashCollapse();

        window.onhashchange = () => ProductFilterCollapse.toggleHashCollapse();
    },

    toggle: (element) => {
        if(!element || !element.length) return;

        if(!ProductFilterCollapse.multiple) {
            let found = ProductFilterCollapse.items.filter(`:not([filter-nav-id=${element.attr('filter-nav-id')}])`);
            found.each((e, element) => ProductFilterCollapse.close($(element)))
        }

        if(element.attr('filter-nav-open') == 'true') return ProductFilterCollapse.close(element);
        return ProductFilterCollapse.open(element);
    },

    close: (element) => {
        if(!element || !element.length) return;

        element.attr('filter-nav-open', 'false');
    },

    open: (element) => {
        if(!element || !element.length) return;

        element.attr('filter-nav-open', 'true');
    },

    toggleHashCollapse: () => {
        let hash = ProductFilterCollapse.getHashAsArray();
        if(!hash.hasOwnProperty('level_1_id')) return;

        let level1Id = hash.level_1_id;
        let level2Id = hash.level_2_id;

        const foundElement = ProductFilterCollapse.items.filter(`[filter-nav-id=${level1Id}]`);
        if(!foundElement || !foundElement.length) return;

        if(foundElement.attr('filter-nav-open') == 'false')
        ProductFilterCollapse.toggle(foundElement);
        ProductFilterCollapse.setActiveCollapse(foundElement);

        if(!level2Id) return ProductFilterCollapse.setActiveItem();
        const foundElementItem = foundElement.find(`*[filter-nav-item-id=${level2Id}]`);

        if(!foundElementItem || !foundElementItem.length) return;
        ProductFilterCollapse.setActiveItem(foundElementItem);
    },

    setActiveItem: (element) => {
        ProductFilterCollapse.items
            .find('.filter-nav__dropdown li')
            .removeClass('active');

        if(!element || !element.length) return;
        element.addClass('active');
    },

    setActiveCollapse: (element) => {
        ProductFilterCollapse.items
            .removeClass('active');

        if(!element || !element.length) return;
        element.addClass('active');
    },

    getHashAsArray: () => {
        let hash = location.hash.substr(1).split('&');
        let params = {};

        hash.forEach(function(val) {
            let splitter = val.split('=');
            params[splitter[0]] = splitter[1];
        });

        return params;
    }
}

if ($('.filterbar').length) {
    ProductFilterCollapse.init();
    ProductFilter.init();
    ProductFilter.reloadProductsFilter(undefined, true);
}
