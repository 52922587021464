import animateScrollTo from 'animated-scroll-to';

const menu = $('.megamenu');
let navItem = $('.megamenu__item');

navItem.on('mouseenter click', function(event) {
    let self = $(this);
    let element = self.attr('data-name');
    let nav = menu.find(`[data-parent="${element}"]`);

    if (nav.length >= 1) {
        hideAllMenus();
        toggleMenu(nav, self);
    }
});

navItem.on('mouseleave', function(event) {
    if(!$('.megamenu__nav.open:hover').length) return hideAllMenus();

    $('.megamenu__nav.open:hover').on('mouseleave', () => {
        return hideAllMenus();
    })
});

$(document).on('click', '.megamenu .close', e => {
    hideAllMenus();
});

$(document).mouseup(e => {
    let menu = $('.megamenu__nav');

    if (!menu.is(e.target) && menu.has(e.target).length === 0) {
        hideAllMenus();
    }
});

const toggleMenu = (element, parent) => {
    parent.toggleClass('active');
    element.toggleClass('open');
    element.attr('megamenu-type', element.attr('megamenu-type') == 'hidden' ? 'show' : 'hidden');

    if ($(document).width() < 757) {
        //animateScrollTo($(element)[0]);
    }
};

const hideMenu = element => {
    element.removeClass('open');
    element.attr('megamenu-type', 'hidden');
};

const hideAllMenus = () => {
    let menus = menu.find('[data-parent]');
    let menuParents = menu.find('[data-name]');

    menus.each(function(index, element) {
        let self = $(this);
        hideMenu(self);
    });

    menuParents.each(function(index, element) {
        let self = $(this);
        self.removeClass('active');
    });
};

const addActiveMenu = () => {
    let activeMenu = $('.js-active-menu').data('value');
    let menuItems = $('.megamenu__item');

    if (activeMenu)
        $.each(menuItems, function(index, element) {
            if ($(element).data('menu') == activeMenu) {
                $(element)
                    .find('.megamenu__item-container')
                    .addClass('active-item');
            }
        });
};

const calculateMegamenuPosition = () => {
    const menus = menu.find('[data-name]');
    menus.each(function(index, element) {
        let id = $(element).attr('data-name');
        let childMenu = menu.find(`[data-parent=${id}]`)

        if(!childMenu.length) return;
        let offset = $(element)[0].offsetTop + $(element).height();
        
        childMenu.css('top', offset);
    });
}

addActiveMenu();

$(document).on('click', '.category', function(e) {
    if ($(this).hasClass('.category__btn')) {
        return;
    }

    e.preventDefault();
    var btn = $(this).find('.category__btn');

    if (!btn.data('toggle') || !$(e.target).hasClass('category__btn')) {
        window.location = $(this)
            .find('a')
            .attr('data-target2');
        return;
    }
});


const ToggleMobileMenu = () => {
    const DropdownToggle = $('[data-dropdown-toggle]');
    const Body = $('body');

    DropdownToggle.on('click', function(e) {
        e.stopPropagation();

        let element = $(this);
        let itemToToggle = element.data('dropdown-toggle');
        let dropDown = $('body').find(`[data-dropdown="${itemToToggle}"]`);

        if (!dropDown || !dropDown.length) return;
        dropDown.toggleClass(`mainnavigation-mobile__item--active`);
    });

    $(document).on('click', function(e) {
        if ($(e.target).closest('[data-dropdown-container').length != 0) return;

        let toHide = $('[data-dropdown]');
        toHide.each(function() {
            $(this).removeClass('mainnavigation-mobile__item--active');
        });
    });
};

const CountMegaMenuItems = () => {
    const container = $('.megamenu-grid');
    if(!container.length) return;

    const items = container.find('.megamenu__item').length >= 3 ? container.find('.megamenu__item').length : 4;
    return container.css('--megamenu-grid-columns', items);
}

CountMegaMenuItems();
ToggleMobileMenu();

jQuery(function() {
    calculateMegamenuPosition();

    $(window).on('resize', () => calculateMegamenuPosition());
})

