/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Cookies from 'js-cookie';
import 'select2';

require('./bootstrap');
require('./bootstrap3-typeahead');

require('./vendor/printThis');
window.Bloodhound = require('./bloodhound');
require('./_navigation');
require('./_bookmarks');
require('./_products');
require('./_product_filter');
require('./_details');
require('./_contact');
require('./_rating');
require('./_slider');
require('./_search');
require('./_print');
require('./_bookmarks_controls');
require('./_eprivacy');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// https://www.npmjs.com/package/lightslider
$('.lightSlider').lightSlider({
    gallery: true,
    item: 1,
    loop: true,
    slideMargin: 0,
    thumbItem: 9,
});

$('.rating').barrating({
    theme: 'bootstrap-stars',
});

$(document).on('click', '[data-toggle="lightbox"]', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$(document).on('click touchstart', '.carousel-item, .carousel-item *', function (e) {
    if ($(this).data('target')) {
        window.location = $(this).data('target');
    }
});

// GENERIC MODAL
$(document).on('hidden', '#ajaxModal', function () {
    $('#modal').removeData('modal');
});

$(document).on('click', 'a[data-toggle=modal]', function () {
    var target = $(this).attr('href');

    $.get(target, function (data) {
        $('#ajaxModal').html(data);
        $('#ajaxModal').modal('show');
    });

    return false;
});


$('.carousel .carousel-inner').swipe({
    swipeLeft: function (event, direction, distance, duration, fingerCount) {
        $('.carousel').carousel('next');
    },
    swipeRight: function () {
        $('.carousel').carousel('prev');
    },
    threshold: 0,
    tap: function (event, target) {
        window.location = $(this).find('.carousel-item.active a').attr('href');
    },
    excludedElements: 'label, button, input, select, textarea, .noSwipe',
});

$('.carousel').swipe({
    swipe: function (event, direction, distance, duration, fingerCount, fingerData) {
        if (direction == 'left') $(this).carousel('next');
        if (direction == 'right') $(this).carousel('prev');
    },
    allowPageScroll: 'vertical',
});

$('.carousel .carousel-control-prev').on('click', function (e) {
    e.preventDefault();
    $('.carousel').carousel('prev');
});
$('.carousel .carousel-control-next').on('click', function (e) {
    e.preventDefault();
    $('.carousel').carousel('next');
});
