const print = (options) => {
    options = { element: false, url: false, title: '', ...options };

    const { element, title, url } = options;

    if (!element && !url) return console.warn('Print - No options given');
    let date = new Date().toLocaleString('de-de');
    const Header =
        `<div class='print-header'>
                <div class='print-logo'>
                <img id='print-logo-img' src='/assets/logo.png'>
                </div>
                <div class='print-date'>
                ` +
        date +
        `
                </div>
        </div>`;

    if (element) {
        const printContainer = $("<div class='print-this'></div>").append($(element).clone());

        return printContainer.printThis({
            importCSS: true,
            header: Header,
            debug: false,
            title: '',
        });
    }

    $.get(url, function (data) {
        let printContainer = $(data);

        printContainer.printThis({
            header: Header,
            debug: false,
            title: '',
        });
    });
};

export default print;
