import Cookies from 'js-cookie';

const checkDetailListInfoBar = () => {
    var categoryid = $('.details.datatable').data('categoryid');

    if (Cookies.get('product_list_state_' + categoryid)) {
        $('#message_list_filtered').show();
    } else {
        $('#message_list_filtered').hide();
    }
};

const addAttributeToSelect = (data, container, id) => {
    $(container).attr('data-parent-id', id);
    return data.text;
};

const initDetailsSelect = () => {
    if (!$('.details-select2').length) return;

    const element = $('.details-select2');

    element.each((key, el) => {
        let dropdownParent = $(el).parent();
        let dropdownParentID = dropdownParent.data('id');
        let dropdownContainer = $(document);
        let element = $(el);

        let select = element.select2({
            dropdownParent: dropdownParent,
            allowClear: true,
            templateResult: (data, container) => addAttributeToSelect(data, container, dropdownParentID),
            templateSelection: (data, container) => addAttributeToSelect(data, container, dropdownParentID),
        });

        dropdownContainer.on('mouseup', `.select2-container--open .select2-results__option.select2-results__option--selected`, function(e) {
            if ($(e.currentTarget).data('parent-id') != select.attr('id')) return;

            select.val('');
            select.trigger('change');
        });

        element.on('click', e => {
            if (!select) return;

            select.select2('open');
        });
    });
};

$('.details.datatable').on('init.dt', function() {
    $('.details.datatable tr').each(function() {
        var categoryid = $('.details.datatable').data('categoryid');
        var id = $(this).data('id');

        if (!id) return;

        if (Cookies.get('product_list_state_' + categoryid)) {
            var ids = Cookies.get('product_list_state_' + categoryid) ? Cookies.get('product_list_state_' + categoryid).split(',') : [];

            if (ids.indexOf('' + id) == -1) {
                $(this).hide();
            }
        }
    });

    checkDetailListInfoBar();
});

$('.details.datatable').DataTable({
    order: [[1, 'asc']],
    info: false,
    paging: false,
    searching: false,
    scrollX: true,
    columnDefs: [
        {
            targets: $('.details.datatable th td').length - 1, // Actionrow!
            orderable: false,
        },
    ],
});

$(document).on('click', '.product_list_reset_selection', function(e) {
    e.preventDefault();
    var categoryid = $('.details.datatable').data('categoryid');
    Cookies.remove('product_list_state_' + categoryid);

    $('.details.datatable tr').show();

    checkDetailListInfoBar();
});

initDetailsSelect();

const debounceTime = 500;

var DetailsFilter = {
    id: $('.filter').data('filter-id'),
    resetButton: $('.js-detail-filter-reset'),
    filterItems: $('.js-detail-filter'),
    filterContainer: $('.filter'),
    filterNoItems: $('.js-filter-no-time'),
    dataTable: $('.details.datatable'),
    applyFilterButton: $('.js-do-filter'),

    url: new URL(window.location.href),

    init: function() {
        DetailsFilter.onResulutLoad();

        DetailsFilter.filterItems.on(
            'change',
            _.debounce(e => DetailsFilter.onUpdateFilter($(e.currentTarget)), debounceTime)
        );

        DetailsFilter.resetButton.on('click', e => DetailsFilter.resetDetailsFilter(e));
        DetailsFilter.applyFilterButton.on('click', e => DetailsFilter.reloadResults(e));

        DetailsFilter.setFilter();
    },

    updateUrl: () => {
        DetailsFilter.url.search
            ? window.history.replaceState(null, null, DetailsFilter.url.search)
            : window.history.replaceState(null, null, window.location.pathname);
    },

    createSearch: ({ key, value }) => {
        if (!key) return;

        if (!value) {
            DetailsFilter.url.searchParams.delete(key);
            return DetailsFilter.updateUrl();
        }

        DetailsFilter.url.searchParams.set(key, value);
        return DetailsFilter.updateUrl();
    },

    setFilter: () => {
        const searchParams = new URLSearchParams(window.location.search);

        if (!searchParams.toString().length) return DetailsFilter.hideResultLoad();

        for (const [key, value] of searchParams.entries()) {
            let element = DetailsFilter.filterContainer.find(`[data-filter-key="${key}"]`);
            if (!element.length) break;

            element.val(value);
            element.trigger('change');
        }
    },

    resetDetailsFilter: e => {
        e.preventDefault();

        DetailsFilter.filterItems.each((key, value) => {
            const element = $(value);

            DetailsFilter.createSearch({
                key: element.data('filter-key'),
                value: null,
            });

            element.val(null);
            element.trigger('change');
        });
    },

    onUpdateFilter: element => {
        DetailsFilter.createSearch({
            key: element.data('filter-key'),
            value: element.val(),
        });

        DetailsFilter.reloadResults();
    },

    reloadResults: () => {
        var ajaxUrl = $('#details_ajax_get_results').val();
        DetailsFilter.onResulutLoad();

        $.ajax(ajaxUrl, {
            method: 'GET',
            data: {
                _token: $('#_token').val(),
                id: DetailsFilter.id,
                params: DetailsFilter.url.searchParams.toString(),
            },
            success: function(resp) {
                DetailsFilter.updateDataTables(resp.data);
            },
            error: function(resp) {
                DetailsFilter.hideResultLoad();
            },
        });
    },

    noResultItems: () => {
        DetailsFilter.filterNoItems.show();
        DetailsFilter.dataTable.find('tbody tr').hide();
    },

    onResulutLoad: () => {
        DetailsFilter.dataTable.find('tbody tr').css('opacity', 0.2);
        DetailsFilter.filterNoItems.hide();
    },

    hideResultLoad: () => {
        DetailsFilter.dataTable.find('tbody tr').css('opacity', 1);
        DetailsFilter.filterNoItems.hide();
    },

    hideFalseResults: () => {
        DetailsFilter.dataTable.find('tbody tr').css('display', 'none');
    },

    updateDataTables: data => {
        DetailsFilter.onResulutLoad();
        if (!data.length) return DetailsFilter.noResultItems();

        DetailsFilter.hideFalseResults();
        data.forEach(product => {
            $(`tr[data-id="${product.id}"]`).css('display', 'table-row');
        });

        return DetailsFilter.hideResultLoad();
    },
};

if ($('.js-filter-init').length) DetailsFilter.init();
